* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}

:root {
  --transition: all 0.4s;
  --whiteColor: #fff;
  --maincolor: #050567;
  --secondColor: #4d52d7;
  --transition: all 0.3s;
  --mainPadding: 0.5rem 3.5rem;
  --secondPadding: 0 3.5rem;
}

main {
  padding: 5.3rem 0 0 0;
}

// LE MENU DE NAVIGATION

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  padding: 1.5rem 5rem !important;
  position: fixed !important;
  width: 100%;
  z-index: 11;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
    }
  }

  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

    .el-menu {
      text-decoration: none;
      color: var(--maincolor);
      font-weight: 600;
      text-transform: uppercase;
    }

    .el-menu:hover {
      color: #4d52d7;
      transition: var(--transition);
    }
  }

  .btn-conn {
    background-color: transparent;
    border: 2px var(--maincolor) solid;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    font-size: 13pt;
    color: var(--maincolor);
    cursor: pointer;
    transition: var(--transition);
  }

  .btn-conn:hover {
    background-color: var(--maincolor);
    color: #fff;
  }

  .nav__buttons {
    display: none;
  }
}

.log {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

.form {
  margin-top: 2rem;
}

// PAGE DE CONNEXION

.log,
.form {
  form {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    width: 450px;
    gap: 1.5rem;

    h3 {
      text-align: center;
    }

    input {
      padding: 0.8rem;
      outline: none;
      border: 1px solid #aeafb5;
      font-size: 16px;
    }

    button {
      padding: 0.7rem;
      border: 1px solid var(--maincolor);
      background-color: var(--maincolor);
      color: #fff;
      font-size: 18px;
      cursor: pointer;
    }

    a {
      color: var(--maincolor);
    }
  }
}

// ACCUEIL

.Accueil {
  background-color: #f4f4f4;
}

.hero {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 20rem;

  .titre,
  .texte {
    color: #fff;
    margin-bottom: 2rem;
  }

  button {
    border: 2px #fff solid;
    padding: 0.5rem 2rem;
    background-color: #09096d;
    border-radius: 0.6rem;
    color: #fff;
  }

  button:hover {
    background-color: #fff;
    border: 2px #fff solid;
    color: #09096d;
    transition: var(--transition);
  }
}

// ACTUALITES

.head {
  padding: 2rem 2rem 3rem 2rem;
  h3 {
    color: #09236b;
    font-weight: 900;
  }
}

.actualites {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    margin: 2rem;
    text-align: center;
  }

  button {
    margin: 1rem 0 3rem 0;
    padding: 0.5rem 0.8rem;
    border: 2px solid #09236b;
    background-color: #09236b;
    color: #fff;
  }

  button:hover {
    background-color: transparent;
    color: #0c5daf;
    transition: var(--transition);
    font-weight: 600;
  }

  a {
    text-decoration: none !important;
    color: #000;
  }

  .articles {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding-bottom: 2rem;

    article {
      width: 360px;
      border-radius: 0.5rem;
      background-color: #fff;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

      .img_article {
        img {
          width: 100%;
          height: 250px;

          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem;
        }
      }

      .texte_article {
        padding: 1rem 1rem 0;

        .titre_article {
          font-size: 16px;
          color: #086dd2;
        }
        .contenu_article {
          font-size: 14px;
        }

        h5 {
          font-weight: 900;
        }
      }
    }

    article:hover {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      transition: var(--transition);
    }
  }
}

.button_afficher_tous_articles {
  padding: 0.7rem;
  font-size: 14px;
  border-radius: 0.6rem;
}

.controls_post {
  display: flex;
  justify-content: center;
  padding: 2rem;
  gap: 0.5rem;
  font-size: 18px;

  span {
    color: #086dd2;
    font-weight: 600;
  }

  button {
    border: none;
    background-color: transparent;
    color: #050567;
  }
}
// FORMULAIRE DE CONTACT

.contact {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #050567;

  justify-content: center;
  align-items: center;

  form.form_contacts {
    width: 500px;
    padding: 2rem;

    input,
    textarea {
      width: 100%;
      border: 1px solid #050567;
      resize: none;
      outline: none;
      padding: 0.5rem;
      margin-top: 1rem;
      font-size: 16px;
      border-radius: 0.3rem;
    }

    button {
      background-color: #fff;
      color: #050567;
      font-size: 14px;
      padding: 0.5rem 0.8rem;
      border: 1px transparent solid;
      cursor: pointer;
      border-radius: 0.3rem;
    }
    button:hover {
      transition: var(--transition);
      background-color: #e7e6e6;
    }
  }
}

// DETAILS

.Details {
  padding: 2rem 20rem 5rem;
  text-align: justify;

  .contenu {
    margin-top: 1rem;
    h3 {
      margin-top: 1rem;
    }

    img {
      height: 580px;
      width: 100%;
    }
  }
}

// CREATION ARTICLE -- PAGE DE REDACTION

.redaction {
  form {
    width: 750px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-size: 18px;

    input,
    select {
      font-size: 16px;
      border: 2px #ccc solid;
      outline: none;
      padding: 0.4rem;
    }

    .contenu {
      margin-bottom: 1.5rem;
    }

    .about {
      height: 150px;
    }

    button {
      border: 2px #1b6ec0 solid;
      background-color: #1b6ec0;
      color: #fff;
      outline: none;
      padding: 0.5rem;
      font-size: 20px;
      cursor: pointer;
    }

    button:hover {
      background-color: #0c5daf;
      border: #0c5daf 2px solid;
      transition: all 0.3s;
    }
  }
}

// LA PAGE 404

.introuvable {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background-color: #09236b;
  color: var(--whiteColor) !important;
  gap: 2rem;

  div {
    font-size: 68pt;
    font-weight: 1000;
  }
  p {
    font-size: 28pt;
  }

  a {
    border: 1px transparent;
    background-color: var(--whiteColor);
    text-decoration: none;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
}

// LES FORMATIONS

.formation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .formations {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;

    article {
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      padding: 0.5rem 1rem;
      border-radius: 0.7rem;
      text-align: justify;
      // height: 120px;

      h6 {
        font-weight: 800;
        color: #3b5bb1;
      }
    }

    a {
      text-decoration: none;
      color: #000;
    }

    article:hover {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      transition: var(--transition);
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
        rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }
  }

  .image_categ_formation {
    img {
      width: 100%;
      height: 300px;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      margin-bottom: 1rem;
    }
  }
}

// LE PIED DE PAGE

.footer__container {
  left: 0;
  width: 100%;
  background-color: #dcd6d6;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

// ADMIN

.admin {
  display: flex;
  padding: 0;
  margin: 0;
  .gauche {
    display: flex;
    flex-direction: column;
    min-width: 100vw;
    height: 100vh;
    .navAdmin {
      color: #000 !important;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
      width: 100%;
      padding: 1rem;
      padding-left: 330px;
      position: absolute;
      background-color: #fff;
      z-index: 100;
      .bouton {
        font-size: 18px;
        font-weight: normal;
        color: #0f46de;
        cursor: pointer;
      }
    }

    .outlet_admin {
      padding: 5rem 3rem 2rem 330px;
      height: 100vh;
      width: 100vw !important;
      overflow-y: auto;

      .element_admin {
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
aside {
  position: fixed;
  width: 300px;
  background-color: #09236b;
  height: 100vh;
  color: #fff;
  padding: 1rem 0 0 1rem;
  z-index: 1000;

  button {
    position: absolute;
    bottom: 2rem;
    background-color: #f20505;
    color: #fff;
    border: 2px solid #fff;
    width: 120px;
    padding: 0.3rem 0.5rem;
    border-radius: 0.5rem;
  }

  button:hover {
    background-color: #fff;
    color: #050567;
    transition: var(--transition);
  }
}

.head-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  color: #050567;
  padding: 1rem;
  width: 100%;
  font-weight: 600;
}
.body-sidebar {
  display: flex;
  flex-direction: column;
  margin-top: 3.5rem;

  a {
    text-decoration: none;
    color: #fff;
    padding: 0.7rem;
  }

  a:hover {
    background-color: #ffffff38;
    transition: var(--transition);
  }

  .message {
    display: flex;
    gap: 2rem;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgb(190, 7, 7);
      padding: 0 7px;
      border-radius: 6px;
      font-size: 12px;
    }
  }
}

.users-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ajoutAgent {
    text-decoration: none;
    color: #fff;
    background-color: #0c5daf;
    border: 2px transparent solid;
    border-radius: 5px;
    padding: 0.3rem 0.7rem;
  }
}

.categories_articles {
  display: flex;
  gap: 1.5rem;

  a {
    text-decoration: none;
    color: #000;
  }
}

.categorie {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  gap: 2rem;
  padding: 0.5rem 1.5rem;
  border-radius: 1rem;
  background-color: #3b5ab131;

  .icone_categ {
    font-size: 18px;
    color: #0c5daf;
  }
  .details_categ {
    display: flex;
    flex-direction: column;
    align-items: center;

    h6 {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
    }
  }
}

.list_articles {
  background: #cccccc41;
  margin-top: 2rem;
  padding: 1.5rem;
  border-radius: 1rem;

  .en_tete {
    margin-bottom: 1.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cat {
      display: flex;
      gap: 1rem;

      button {
        padding: 0.3rem 0.6rem;
        background-color: #3b5ab131;
        border: transparent;
        border-radius: 0.5rem;
        font-weight: 600;
      }
    }
    a {
      text-decoration: none;
      background-color: #050567;
      padding: 0.5rem 0.7rem;
      color: #fff;
      border-radius: 0.5rem;
    }
    h6 {
      font-weight: 600;
    }
  }

  .corps {
    .actions_articles {
      display: flex;
      gap: 1rem;

      a {
        text-decoration: none;
        font-size: 20px;
      }
    }

    .photo-profil {
      img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 2px #000 solid;
      }
    }

    .controls {
      display: flex;
      gap: 1rem;
      justify-content: flex-end;

      button {
        background-color: transparent;
        border: transparent;
      }
      span {
        color: #086dd2;
        font-weight: 600;
      }
    }
  }
}

.bouton__con {
  background-color: #050567;
  padding: 0.5rem 0.5rem;
  color: #fff !important;
  border-radius: 0.3rem;
  border: 2px transparent solid;
}
.bouton__con:hover {
  background-color: #fff;
  border: 2px #050567 solid;
  color: #050567 !important;
}
// MEDIAS QUERIES

@media screen and (max-width: 1024px) {
  .head {
    padding: 1rem 0 0 0;
    h3 {
      margin-left: 2rem;
      color: #09236b;
      font-weight: 900;
    }
  }

  // LA PARTIE HERO

  .hero {
    padding: 2rem;
    height: 87vh;
    width: 100vw;

    h1 {
      font-size: 24px;
    }
  }

  // LA PARTIE ACTUALITES

  .actualites {
    .articles {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      article {
        img {
          width: 100%;
          padding: 0.3rem;
        }
      }
    }
  }

  // LA PARTIE DETAILS

  .Details {
    padding: 0 3rem;
    text-align: justify;

    .contenu {
      img {
        height: 250px;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  // LA BARRE DE NAVIGATION
  .navbar {
    position: relative;
    align-items: center;
    padding: 1.6rem !important;

    .menu {
      position: absolute;
      transition: var(--transition);
      top: 5.4rem;
      left: 0;
      flex-direction: column;
      background-color: #fff;
      width: 100%;
      justify-content: flex-start;
      padding: 1rem !important;
      height: 100vh;
      overflow-x: hidden;
      align-items: flex-start;
      color: #3f65e1;

      animation-name: menu_xd;
      animation-duration: 0.4s;

      a {
        color: var(--whitecolor) !important;
        width: 100%;
      }

      .btn-conn {
        background-color: transparent;
        border: 2px var(--whitecolor) solid;
        padding: 0.5rem 1rem;
        border-radius: 1rem;
        font-size: 13pt;
        color: var(--whitecolor);
        cursor: pointer;
        transition: var(--transition);
      }
    }

    .nav__buttons {
      display: inline-block;
      font-size: 24px;
      font-weight: 900;
      color: #1149e2;
    }
  }
}

@media screen and (max-width: 678px) {
  main {
    padding: 2rem 0 0 0;
  }

  .head {
    padding: 5rem 0 0 0;
    h3 {
      margin-left: 2rem;
      color: #09236b;
      font-weight: 900;
    }
  }

  // LA PARTIE HERO

  .hero {
    height: 100vh;
    align-items: center;
    justify-content: center;
  }

  // LA PARTIE ACTUALITES

  .actualites {
    padding-top: 2rem;
    .articles {
      display: flex;
      flex-direction: column;
      padding: 1rem;

      article {
        width: 100% !important;
      }
    }
  }

  // LA PARTIE CONTACT

  .contact {
    padding-bottom: 2rem;
    form.form_contacts {
      width: 100%;
      padding: 1.2rem;
    }
  }

  .Details {
    padding: 4rem 1rem 1.3rem 1rem;
    text-align: justify;
  }

  // LA PARTIE FORMATIONS
  .formation {
    .formations {
      display: flex;
      flex-direction: column;
      padding: 1.2rem;
    }
  }

  .log {
    padding: 1rem;
  }
}

// LES ANIMATIONS

// Ouverture du menu
@keyframes menu_xd {
  from {
    transform: translateX(200%);
  }
}
